@tailwind base;
@tailwind components;
@tailwind utilities;

#modal-create-product {
  max-width: 60rem;
  margin: auto;
}

#modal-create-product .prices,
#modal-create-product .services,
#modal-create-product .required-documents {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#modal-create-product .prices .header,
#modal-create-product .prices .price {
  display: grid;
  align-items: center;
  grid-template-columns: 1.3fr 1fr 1fr auto;
  gap: 1rem;
}

#modal-create-product .prices .price:not(:last-child):not(:first-child) {
  padding-bottom: 1rem;
  border-bottom: 1px solid lightgray;
}

#modal-create-product .services .header,
#modal-create-product .services .service {
  display: grid;
  align-items: center;
  grid-template-columns: auto minmax(0,1.5fr) 1fr 2rem 3rem;
  gap: 1rem;
}

#modal-create-product .required-documents .document {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  gap: 1rem;
}

#modal-create-product .services .service .input-actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@media only screen and (max-width: 767px) {
  #modal-create-product .prices .header,
  #modal-create-product .prices .price {
    grid-template-columns: repeat(5, 1fr);
  }
  #modal-create-product .prices .price:not(:last-child) {
    padding-bottom: 1rem;
  }
  #modal-create-product .prices .price .input-1 {
    grid-column-start: 1;
    grid-column-end: 5;
  }
  #modal-create-product .prices .price .input-2 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  #modal-create-product .prices .price .input-3 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 5;
  }
  #modal-create-product .prices .price .input-actions {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 5;
    grid-column-end: 6;
  }

  #modal-create-product .services .service {
    grid-template-columns: repeat(4, 1fr);
  }
  #modal-create-product .services .service:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid lightgray;
  }
  #modal-create-product .services .service .input-1 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  #modal-create-product .services .service .input-2 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 6;
  }
  #modal-create-product .services .service .input-actions {
    grid-column-start: 3;
    grid-column-end: 6;
    justify-content: flex-end;
  }
}

#modal-create-product .services .header span,
#modal-create-product .prices .header span {
  line-height: 14px;
  font-weight: 600;
}

#modal-create-product .deducible input[type=number]
#modal-create-product .deducible input[type=number]::-webkit-outer-spin-button,
#modal-create-product .deducible input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}

#modal-create-product .dropzone {
  width: 100%;
  position: relative;
  border-width: 2px;
  border-style: dashed;
  border-radius: 0.5rem;
  padding: 1.5rem;
}